import QueryString from "./src/helper/QueryString";
// require("./src/styles/index.scss");

export const onRouteUpdate = () => {
    let query = new QueryString();

    if (typeof window !== "undefined") {
        query.parseQuery(window.location.search);
        if (!query.getQuery().originalLandingPage) {
            query.setValue("originalLandingPage", window.location.href);
        }
    }

    if (
        typeof document !== "undefined" &&
        typeof document.referrer !== "undefined" &&
        document.referrer !== ""
    ) {
        query.setValue("referrer", document.referrer);
    }
};
// export const onClientEntry = () => {
//     console.log("Script executed before React renders");
//     if (typeof window !== "undefined") {
//         // setTimeout(() => {
//         const nowTime = Date.now();

//         let userTrack = JSON.parse(localStorage.getItem("userTrack")) ?? {
//             lastVisitTrack: nowTime,
//         };

//         const is24HoursElapsed =
//             nowTime == userTrack.lastVisitTrack
//                 ? true
//                 : nowTime - userTrack.lastVisitTrack > 2592000000;
//         console.log("is24HoursElapsed", is24HoursElapsed);
//         console.log("userTrack", userTrack);
//         console.log(
//             "nowTime - userTrack.lastVisitTrack",
//             nowTime - Number(userTrack.lastVisitTrack)
//         );

//         if (userTrack && is24HoursElapsed) {
//             fetch(process.env.GATSBY_API_URL + "/userTrack").then((response) =>
//                 response.json().then((data) => {
//                     // console.log("data is", data);
//                     localStorage.setItem(
//                         "userTrack",
//                         JSON.stringify({
//                             lastVisitTrack: Date.now(),
//                             usertype: data.usertype,
//                         })
//                     );

//                     if (data.usertype && Boolean(data.usertype % 4 == 0)) {
//                         window.location.href =
//                             "https://revamp.ma-development.de";
//                     }
//                 })
//             );
//         }
//         userTrack = JSON.parse(localStorage.getItem("userTrack")) ?? {
//             lastVisitTrack: nowTime,
//         };
//         if (userTrack.usertype && Boolean(userTrack.usertype % 4 == 0)) {
//             window.location.href = "https://revamp.ma-development.de";
//         }
//         // }, 100);
//     }
// };
