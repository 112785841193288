import React from "react";
import { Link } from "gatsby";

function CoronaBanner() {
    return (
        <div className="columns is-mobile corona-banner is-hidden-desktop">
            <div className="column" />
            <div className="column is-narrow message-box">
                <Link to="/preisrechner/">
                    <div className="columns is-vcentered is-mobile content-box">
                        <div className="column is-narrow">
                            <i
                                className="fas fa-piggy-bank left is-size-3"
                                style={{ color: "#1895ff" }}
                            ></i>
                        </div>
                        <div className="column is-size-6 is-hidden-mobile message">
                            Jetzt noch für kurze Zeit:{" "}
                            <strong>-10% Winter-Rabatt</strong> auf unsere
                            Sicherheitstechnik
                        </div>
                        <div className="column is-size-7 is-hidden-tablet message">
                            Jetzt noch für kurze Zeit:{" "}
                            <strong>-10% Winter-Rabatt</strong>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="column" />
        </div>
    );
}

export default CoronaBanner;
