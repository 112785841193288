import React from "react";
import Fade from "react-reveal/Fade";
import moment from "moment";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import DefaultHero from "../components/DefaultHero";
// import LeadPartnerExklusives from "../components/LeadPartnerExklusives";
import Footer from "../components/Footer";
import QueryString from "../helper/QueryString";
// import { window } from "global";

// const window = require("global");

// import { useSearchParams } from "react-router-dom";
// console.log("window", window);
export const HeroElement = (props) => {
    console.log("risikoeinschaetzung  component loaded 1");

    let sending = false;
    let sent = false;

    const queryString = new QueryString();
    // const [searchParams, setSearchParams] = useSearchParams();
    let searchParams = { get: () => {} };
    if (typeof window !== `undefined`) {
        searchParams = new URLSearchParams(window.location.search);
    }

    console.log("query", queryString.readQuery());
    const query = queryString.readQuery();

    console.log("props.query", query);
    console.log("props.url", props.url);
    console.log("props.params", props.params);
    console.log("props.props ", props.props);
    console.log("searchParams ", searchParams);
    // console.log("searchParams: utm_source", query."utm_source"));
    // console.log("searchParams: utm_medium", query."utm_medium"));

    searchParams.get("utm_medium");
    const formData = {
        salutation: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        technicalSource: "Burglary Atlas",
        // marketingSource: "Cooperation-Partner",
        // marketingSource: "Lead-Partner",
        leadStatus: "New",
        region: "Unknown",
        customerType: "Unknown",
        systemCategory: "Unknown",
        company: "",
        // utm_source: "",
        // utm_medium: "",
        generatedTime: moment().utc().format("YYYY-MM-DDTHH:mm:ss"),
    };
    // if (searchParams.get("utm_source")) {
    //     formData.utm_source = searchParams.get("utm_source");
    // }
    // if (searchParams.get("utm_medium")) {
    //     formData.utm_medium = searchParams.get("utm_medium");
    // }
    let body = Object.assign(formData, query);

    return (
        <div>
            <div className="price-calculator-columns">
                <p className="kwkh__page-title">
                    MeinAlarm24 Risikoeinschätzung durchführen lassen
                </p>
                <div className="box price-box is-half is-offset-one-quarter">
                    <article className="media price-calculator">
                        <div className="media-content">
                            <div
                                id="checkout"
                                className="content checkout"
                                key="checkout"
                            >
                                <Fade right>
                                    <div className="columns checkout-top is-mobile">
                                        <div className="column is-narrow checkout-top-left has-text-centered">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="column is-vertical-center checkout-top-middle">
                                            <span className="is-size-4-tablet">
                                                Sie sind nur noch einen Schritt
                                                davon entfernt, Ihre
                                                individuelle Risikoeinschätzung
                                                für die PLZ {query.PLZ}{" "}
                                                erstellen zu lassen.
                                            </span>
                                            <div className="arrow-down"></div>
                                        </div>
                                    </div>
                                    <div className="columns checkout-middle">
                                        <div className="column">
                                            <div className="columns">
                                                <div className="column has-text-centered checkout-middle-top">
                                                    Bitte tragen Sie Ihre
                                                    Kontaktdaten ein, um Ihre
                                                    Risikoeinschätzung zu
                                                    erhalten.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <form
                                        id="checkoutForm"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            if (e.target.checkValidity()) {
                                                formData.company = `${formData.firstName} ${formData.lastName}`;
                                                if (!formData.salutation) {
                                                    formData.salutation =
                                                        "Herr";
                                                }
                                                // Cookies.remove("query");

                                                console.log(
                                                    "formData.utm_source:inner",
                                                    formData.utm_source
                                                );
                                                //logs for test
                                                console.log(
                                                    "formData.utm_medium:inner",
                                                    formData.utm_medium
                                                );
                                                fetch(
                                                    process.env.GATSBY_API_URL +
                                                        "/landing",
                                                    // `https://api.ma-development.de/landing`,
                                                    // `http://localhost:3000/landing`,
                                                    {
                                                        method: "POST",
                                                        headers: {
                                                            Accept: "application/json",
                                                            "Content-Type":
                                                                "application/json",
                                                        },
                                                        body: JSON.stringify(
                                                            body
                                                        ),
                                                    }
                                                )
                                                    .then(() => {
                                                        sent = true;

                                                        document
                                                            .querySelector(
                                                                "#checkout"
                                                            )
                                                            .classList.add(
                                                                "hide"
                                                            );
                                                        document
                                                            .querySelector(
                                                                "#thanks"
                                                            )
                                                            .classList.remove(
                                                                "hide"
                                                            );
                                                        document.querySelector(
                                                            "#phone"
                                                        ).innerHTML =
                                                            formData.phone;
                                                    })
                                                    .catch((err) => {
                                                        console.log(
                                                            "errr",
                                                            err
                                                        );
                                                        alert(
                                                            "Something went wrong"
                                                        );
                                                    });
                                            }
                                        }}
                                    >
                                        <div className="columns">
                                            <div className="column checkout-middle-left">
                                                <div className="columns">
                                                    <div className="column">
                                                        <div className="select">
                                                            <select
                                                                defaultValue={
                                                                    "Herr"
                                                                }
                                                                name="salutation"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formData.salutation =
                                                                        e.target.value;
                                                                }}
                                                            >
                                                                <option value="Herr">
                                                                    Herr
                                                                </option>
                                                                <option value="Frau">
                                                                    Frau
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="columns">
                                                    <div className="column vorname">
                                                        <input
                                                            className="input"
                                                            placeholder="Vorname"
                                                            type="text"
                                                            name="firstName"
                                                            required
                                                            onChange={(e) => {
                                                                formData.firstName =
                                                                    e.target.value;
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="column nachname">
                                                        <input
                                                            className="input"
                                                            placeholder="Nachname"
                                                            type="text"
                                                            name="lastName"
                                                            required
                                                            onChange={(e) => {
                                                                formData.lastName =
                                                                    e.target.value;
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="columns">
                                                    <div className="column">
                                                        <input
                                                            className="input"
                                                            placeholder="E-Mail"
                                                            type="email"
                                                            name="email"
                                                            required
                                                            onChange={(e) => {
                                                                formData.email =
                                                                    e.target.value;
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="columns">
                                                    <div className="column input-number">
                                                        <input
                                                            className="input "
                                                            placeholder="Telefon"
                                                            type="number"
                                                            name="phone"
                                                            required
                                                            onChange={(e) => {
                                                                formData.phone =
                                                                    e.target.value;
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="columns">
                                                    <div className="column datenschutz">
                                                        <i className="fas fa-lock"></i>
                                                        Die Sicherheit Ihrer
                                                        Daten ist uns wichtig!
                                                        Ihre Daten werden streng
                                                        vertraulich behandelt
                                                        und nicht an Dritte
                                                        weitergegeben.
                                                    </div>
                                                </div>
                                                <div className="columns">
                                                    <div className="column submit">
                                                        <button
                                                            id="checkoutSubmit"
                                                            className={
                                                                "button preisrechner-btn preisrechner-btn-primary checkout-button" +
                                                                (sending
                                                                    ? " is-loading"
                                                                    : "")
                                                            }
                                                        >
                                                            Jetzt
                                                            Risikoeinschätzung
                                                            anfordern
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    className="columns is-mobile is-hidden-tablet is-vcentered"
                                                    style={{
                                                        backgroundColor:
                                                            "#f2f2f2",
                                                        margin: "0",
                                                        marginTop: "12px",
                                                        marginBottom: "12px",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <div
                                                        className="column is-narrow"
                                                        style={{
                                                            paddingTop: "0px",
                                                            paddingBottom:
                                                                "0px",
                                                            paddingRight: "0px",
                                                            paddingLeft: "10px",
                                                        }}
                                                    >
                                                        <i
                                                            className="fas fa-piggy-bank left is-size-3"
                                                            style={{
                                                                color: "#1895ff",
                                                                verticalAlign:
                                                                    "middle",
                                                            }}
                                                        ></i>
                                                    </div>
                                                    <div
                                                        className="column is-size-6 has-text-centered"
                                                        style={{
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        Jetzt noch für kurze
                                                        Zeit:{" "}
                                                        <strong>
                                                            -10% Winter-Rabatt
                                                        </strong>{" "}
                                                        auf unsere
                                                        Sicherheitstechnik
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                    className="columns is-mobile is-hidden-tablet is-vcentered"
                                                >
                                                    <div className="column">
                                                        <img
                                                            src="/img/Screenshot_Risikoeinschaetzung_Checkout.jpg"
                                                            onClick={() =>
                                                                document
                                                                    .getElementById(
                                                                        "checkoutSubmit"
                                                                    )
                                                                    .click()
                                                            }
                                                            alt="persönliche Preiskalkulation"
                                                        />
                                                    </div>
                                                    <div className="column">
                                                        <img
                                                            src="/img/Trustsymbols Checkout.jpg"
                                                            onClick={() =>
                                                                document
                                                                    .getElementById(
                                                                        "checkoutSubmit"
                                                                    )
                                                                    .click()
                                                            }
                                                            alt="persönliche Preiskalkulation"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column checkout-middle-right is-hidden-mobile">
                                                <div
                                                    style={{ marginTop: "0px" }}
                                                    className="is-hidden-mobile columns"
                                                >
                                                    <div className="column ">
                                                        <div>
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        "2px",
                                                                }}
                                                            >
                                                                <b className="is-size-7 img-label">
                                                                    {/* Unser Kunden-werben-Kunden Programm */}
                                                                </b>
                                                            </p>
                                                            <img
                                                                src="/img/Screenshot_Risikoeinschaetzung_Checkout.jpg"
                                                                onClick={() =>
                                                                    document
                                                                        .getElementById(
                                                                            "checkoutSubmit"
                                                                        )
                                                                        .click()
                                                                }
                                                                alt="persönliche Preiskalkulation"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="column">
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                }}
                                                            >
                                                                <b
                                                                    className="is-size-7"
                                                                    style={{
                                                                        color: "white",
                                                                    }}
                                                                >
                                                                    Gefördert
                                                                    durch:
                                                                </b>
                                                            </p>
                                                            <img
                                                                style={{
                                                                    padding:
                                                                        "2px",
                                                                    height: "100px",
                                                                }}
                                                                src="/img/Trustsymbols Checkout.jpg"
                                                                onClick={() =>
                                                                    document
                                                                        .getElementById(
                                                                            "checkoutSubmit"
                                                                        )
                                                                        .click()
                                                                }
                                                                alt="persönliche Preiskalkulation"
                                                            />
                                                        </div>
                                                        <div
                                                            className="columns is-vcentered"
                                                            style={{
                                                                backgroundColor:
                                                                    "#f2f2f2",
                                                                margin: "0",
                                                                marginTop:
                                                                    "8px",
                                                                marginBottom:
                                                                    "0px",
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <div className="column">
                                                                <div
                                                                    className="has-text-centered"
                                                                    style={{
                                                                        paddingTop:
                                                                            "0px",
                                                                        paddingBottom:
                                                                            "5px",
                                                                        paddingRight:
                                                                            "0px",
                                                                        paddingLeft:
                                                                            "0px",
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fas fa-piggy-bank left is-size-3"
                                                                        style={{
                                                                            color: "#1895ff",
                                                                            verticalAlign:
                                                                                "middle",
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                                <div
                                                                    className="is-size-7 has-text-centered"
                                                                    style={{
                                                                        paddingLeft:
                                                                            "0px",
                                                                    }}
                                                                >
                                                                    Jetzt noch
                                                                    für kurze
                                                                    Zeit:{" "}
                                                                    <strong>
                                                                        -10%
                                                                        Winter-Rabatt
                                                                    </strong>{" "}
                                                                    auf unsere
                                                                    Sicherheitstechnik
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div>
                                        <div className="columns checkout-bottom vorteil">
                                            <div className="column checkout-bottom vorteil-left has-text-centered">
                                                <i className="fas fa-check"></i>
                                                <span
                                                    style={{
                                                        fontSize: "0.9rem",
                                                    }}
                                                >
                                                    Kostenlose Beratung
                                                </span>
                                            </div>
                                            <div className="column checkout-bottom vorteil-middle has-text-centered">
                                                <i className="fas fa-check"></i>
                                                <span
                                                    style={{
                                                        fontSize: "0.9rem",
                                                    }}
                                                >
                                                    99,2% Kundenzufriedenheit
                                                </span>
                                            </div>
                                            <div className="column checkout-bottom vorteil-right has-text-centered">
                                                <i className="fas fa-check"></i>
                                                <span
                                                    style={{
                                                        fontSize: "0.9rem",
                                                    }}
                                                >
                                                    Zertifizierte Techniker
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div
                                id="thanks"
                                className="thanks hide"
                                key="thanks"
                            >
                                <Fade right>
                                    <div className="columns thanks-top is-mobile">
                                        <div className="column is-narrow thanks-top-left has-text-centered">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="column is-vertical-center thanks-top-middle">
                                            <span className="is-size-4-tablet">
                                                Herzlichen Dank für Ihre
                                                Preisanfrage!
                                            </span>
                                            <div className="arrow-down"></div>
                                        </div>
                                    </div>
                                    <div className="content has-text-centered">
                                        <div className="columns">
                                            <div className="column">
                                                <figure className="image customer-agent">
                                                    <img
                                                        src="/img/image_2_kostenlose_beratung.svg"
                                                        alt="Kostenlose Beratung"
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                        <p className="is-size-5">
                                            <strong>Wie geht es weiter?</strong>
                                        </p>
                                        <p className="is-size-5">
                                            Ein MeinAlarm24 Sicherheitsberater
                                            berechnet aktuell einen ersten Preis
                                            für Ihr Projekt und wird sich bei
                                            offen gebliebenen Fragen unter der
                                            folgenden Telefonnummer bei Ihnen
                                            melden:
                                        </p>
                                        <p className="is-size-5">
                                            <strong id="phone"></strong>
                                        </p>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default () => {
    return (
        <StaticQuery
            query={graphql`
                query RisikoeinschaetzungQuery {
                    site {
                        siteMetadata {
                            title
                            image
                            image768
                            image1024
                            image1216
                            image1408
                        }
                    }
                }
            `}
            render={(data) => {
                console.log("risikoeinschaetzung  component loaded 2");
                const contextData = {
                    title: data.site.siteMetadata.title,
                    image: "/img/hero/" + data.site.siteMetadata.image,
                    image768: "/img/hero/" + data.site.siteMetadata.image768,
                    image1024: "/img/hero/" + data.site.siteMetadata.image1024,
                    image1216: "/img/hero/" + data.site.siteMetadata.image1216,
                    image1408: "/img/hero/" + data.site.siteMetadata.image1408,
                    showPriceCalculator: false,
                    showHeroMobile: false,
                    showBottom: false,
                    disableBackground: true,
                    defaultCssClass: "kwkh height-more",
                };
                contextData.showElement = <HeroElement context={contextData} />;
                return (
                    <div className="risi">
                        <Helmet>
                            <script src={"/js/callus.js"} />
                            <html className="has-navbar-fixed-top" />
                            <title>MeinAlarm24 Lead-Partner Programm</title>
                            <meta name="description" content="" />
                            <meta name="keywords" content="" />
                            <meta property="og:type" content="article" />
                            <meta property="og:title" content="" />
                            <meta property="og:description" content="" />
                            <meta
                                property="og:image"
                                content={data.site.siteMetadata.image}
                            />
                        </Helmet>
                        {/* <LeadPartnerExklusives /> */}
                        <div style={{ marginTop: "4rem" }}></div>
                        <DefaultHero context={contextData} />
                        <Footer small={true} />
                    </div>
                );
            }}
        />
    );
};
